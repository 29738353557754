import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { PatchedPagination } from "../../../components/PatchedPagination/patchedPagination";
import {
    InserirAutorizacaoFornecimentoStatusRequest,
    LimparModalConfimacaoStatusFinalizado,
    ExcluirAutorizacaoFornecimentoStatusRequest,
    ExcluirAutorizacaoFornecimentoStatusReabrirAutorizacaoRequest
} from "../../../store/modules/autorizacaoFornecimentoStatus/actions";
import { ExcluirAutorizacaoFornecimentoRequest } from "../../../store/modules/autorizacaoFornecimento/actions";
import { BuscarUsuarioPorIdRequest } from "../../../store/modules/usuario/actions";
import { ListarUsuarioRequest } from '../../../store/modules/usuario/actions';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table';
import moment from 'moment';
import Select from 'react-select';
import 'moment/locale/pt-br';
import './status.css';

export default function Status() {

    const { idAutorizacaoFornecimento } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let [usuario] = useState(JSON.parse(localStorage.getItem('token')).usuario);

    const usuarioCompleto = useSelector(state => state.usuario.usuario);
    const listaContratoUsuario = useSelector(state => state.contratoUsuario.listaContratoUsuario);
    const listaStatusAutorizacaoFornecimento = useSelector(state => state.statusAutorizacaoFornecimento.listaStatusAutorizacaoFornecimento);
    const listaAutorizacaoFornecimentoStatus = useSelector(state => state.autorizacaoFornecimentoStatus.listaAutorizacaoFornecimentoStatus);
    const autorizacaoFornecimentoCompleto = useSelector(state => state.autorizacaoFornecimento.autorizacaoFornecimentoCompleto);
    const listaMedicaoAutorizacaoFornecimento = useSelector(state => state.medicaoAutorizacaoFornecimento.listaMedicaoAutorizacaoFornecimento);
    const listaStatusMedicaoAutorizacaoFornecimento = useSelector(state => state.statusMedicaoAutorizacaoFornecimento.listaStatusMedicaoAutorizacaoFornecimento);
    const ativaModalConfimacaoFinalizado = useSelector(state => state.autorizacaoFornecimentoStatus.modalConfirmacaoStatusFinalizado);
    const listaUsuario = useSelector(state => state.usuario.listaUsuario);

    const [descricaoObservacaoCancelado, setDescricaoObservacaoCancelado] = useState("");
    const [descricaoObservacaoExclusao, setDescricaoObservacaoExclusao] = useState("");
    const [showExclusao, setShowExclusao] = useState(false);
    const [show, setShow] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showNovoStatus, setShowNovoStatus] = useState(false);
    const [showConfirmacaoFinalizar, setShowConfirmacaoFinalizar] = useState(false);
    const [status, setStatus] = useState('');
    const [statusExcluir, setStatusExcluir] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [validated, setValidated] = useState(false);
    const [idUsuario, setIdUsuario] = useState(null);

    const handleCloseExclusao = () => setShowExclusao(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);
    const handleCloseNovoStatus = () => setShowNovoStatus(false);
    const handleShowNovoStatus = () => setShowNovoStatus(true);
    const handleCloseConfirmacaoFinalizar = () => {
        setShowConfirmacaoFinalizar(false);
        dispatch(LimparModalConfimacaoStatusFinalizado());
    }
    const handleShowConfirmacaoFinalizar = () => setShowConfirmacaoFinalizar(true);

    const options = listaUsuario != undefined ?
        listaUsuario.map(u => ({
            value: u.id, label: u.nome
        }))
        :
        [];

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);
    const [excluir, setExcluir] = useState(false);

    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r === "AutorizacaoFornecimentoVerValores").length === 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r === "AutorizacaoFornecimentoInserir").length === 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r === "AutorizacaoFornecimentoAlterar").length === 1) {
            setAlterar(true);
        }
        if (token.role.filter(r => r === "AutorizacaoFornecimentoxcluir").length === 1) {
            setExcluir(true);
        }

    }, [])

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarUsuarioRequest());

        if (usuario != null && usuario !== undefined) {
            BuscarUsuarioPorIdRequest(usuario.id);
        }

    }, [idAutorizacaoFornecimento])

    useEffect(() => {

        if (ativaModalConfimacaoFinalizado === true)
            handleShowConfirmacaoFinalizar();

    }, [ativaModalConfimacaoFinalizado])

    const handleSubmit = (event) => {

        //Verifica se foi inserido algo na descrição de cancelamento
        if (descricaoObservacaoCancelado.trim() === '') {
            event.preventDefault();
        } else {
            CancelarAutorizacao(event);
        }

        setValidated(true);
    };

    function InserirAutorizacaoFornecimentoStatus() {

        let proximoStatus = '';
        var verificacao = true;

        if (listaStatusAutorizacaoFornecimento !== undefined) {
            proximoStatus = listaStatusAutorizacaoFornecimento.filter(x => x.descricao === status);
        }

        if (proximoStatus.length > 0 && proximoStatus[0].statusFornecedor) {
            // Verificar se é fornecedor
            if (usuarioCompleto != null && !usuarioCompleto.usuarioFornecedor) {
                toast.error("Você precisa ser fornecedor para avançar nesse status!");
                verificacao = false;
                return;
            }
        }

        if (proximoStatus.length > 0 && !proximoStatus[0].statusFornecedor) {
            // Verificar se é cliente
            if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {
                toast.error("Você precisa ser cliente para avançar nesse status!");
                verificacao = false;
                return;
            }
        }

        if (verificacao && proximoStatus.length > 0) {
            let data = {
                'Id': 0,
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento),
                'IdStatusAutorizacaoFornecimento': proximoStatus[0].id,
                'IdUsuario': idUsuario ? idUsuario : usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'Observacao': ''
            }

            if (proximoStatus[0].finalizaAutorizacao) {

                if (ativaModalConfimacaoFinalizado) {
                    dispatch(InserirAutorizacaoFornecimentoStatusRequest(data, idAutorizacaoFornecimento, true));
                    dispatch(LimparModalConfimacaoStatusFinalizado());
                    handleCloseConfirmacaoFinalizar();
                } else {
                    dispatch(InserirAutorizacaoFornecimentoStatusRequest(data, idAutorizacaoFornecimento, false));
                }

            } else {
                dispatch(InserirAutorizacaoFornecimentoStatusRequest(data, idAutorizacaoFornecimento, true));
            }

        }

        handleCloseStatus();
    }

    function CancelarAutorizacao(e) {

        e.preventDefault();

        let proximoStatus = [];
        var validaMedicoes = true;
        var statusFinalizaMedicao = '';

        // Busca o status de cancelado
        if (listaStatusAutorizacaoFornecimento !== undefined) {
            proximoStatus = listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao === true);
        }

        // Verificar se tem medições finalizadas
        if (listaStatusMedicaoAutorizacaoFornecimento !== undefined) {
            statusFinalizaMedicao = listaStatusMedicaoAutorizacaoFornecimento.filter(x => x.finalizaMedicao === true);
        }

        if (listaMedicaoAutorizacaoFornecimento !== undefined && listaStatusMedicaoAutorizacaoFornecimento !== undefined
            && statusFinalizaMedicao.length > 0) {
            listaMedicaoAutorizacaoFornecimento.map(subitem => {
                if (statusFinalizaMedicao[0].descricao === subitem.statusAtual) {
                    validaMedicoes = false;
                }
            })
        }

        //validação do campo observação
        if (descricaoObservacaoCancelado === null || descricaoObservacaoCancelado === undefined
            || descricaoObservacaoCancelado === ' ' || descricaoObservacaoCancelado.trim() === '') {
            toast.error(`Observação inválida`);
            return;
        }

        //validação do tamanho da string
        if (descricaoObservacaoCancelado.length > 500) {
            toast.error(`A Observação não pode conter mais que 500 caracteres`);
            return;
        }

        if (proximoStatus.length > 0 && !proximoStatus[0].statusFornecedor) {
            // Verificar se é cliente
            if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {
                toast.error("Você precisa ser cliente para cancelar a autorização!");
                return;
            }
        }

        // Insere o status Cancelado
        if (validaMedicoes) {
            if (proximoStatus.length > 0) {
                let data = {
                    'Id': 0,
                    'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento),
                    'IdStatusAutorizacaoFornecimento': proximoStatus[0].id,
                    'IdUsuario': usuario.id,
                    'DataStatus': moment((new Date())).add('hours', 2),
                    'Observacao': descricaoObservacaoCancelado
                }

                dispatch(InserirAutorizacaoFornecimentoStatusRequest(data, idAutorizacaoFornecimento, true));
                setDescricaoObservacaoCancelado("");
                setValidated(false);
                handleClose();
            }
        } else {
            toast.error("Já possui medições finalizadas. Não é possível cancelar a autorização!");
        }
    }

    function ReativarAutorizacao() {

        let proximoStatus = [];

        if (listaStatusAutorizacaoFornecimento !== undefined) {
            proximoStatus = listaStatusAutorizacaoFornecimento.filter(x => x.iniciaAutorizacao == true);
        }

        // Verificar se é cliente
        if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {
            toast.error("Você precisa ser cliente para cancelar a autorização!");
            return;
        }

        if (proximoStatus.length > 0) {
            let data = {
                'Id': 0,
                'IdAutorizacaoFornecimento': parseInt(idAutorizacaoFornecimento),
                'IdStatusAutorizacaoFornecimento': proximoStatus[0].id,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'Observacao': ''
            }

            dispatch(InserirAutorizacaoFornecimentoStatusRequest(data, idAutorizacaoFornecimento, true));
            setValidated(false);
        }

        handleCloseNovoStatus();
    }

    function CancelarStatusClick(statusParametro) {
        setStatus(statusParametro);
        handleShow();
    }

    function ResetarStatus(idStatus) {
        setStatusExcluir(idStatus);
        handleShowNovoStatus();
    }

    function MudarStatusClick(statusParametro) {
        setStatus(statusParametro);
        handleShowStatus();
    }

    function ExcluirUltimoStatus() {
        dispatch(ExcluirAutorizacaoFornecimentoStatusRequest(idAutorizacaoFornecimento));
    }

    function HandleShowExcluirAutorizacaoFornecimento() {
        setShowExclusao(true);
    }

    function ExcluirAutorizacaoFornecimento() {
        if(!descricaoObservacaoExclusao?.trim()){
            toast.error("Observação Obrigatória.");
            return;
        }
        else{
            dispatch(ExcluirAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento, descricaoObservacaoExclusao));
        }
    }

    function ReabrirAFStatus(){

        if(listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
        listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0){
            dispatch(ExcluirAutorizacaoFornecimentoStatusReabrirAutorizacaoRequest(idAutorizacaoFornecimento));
        }
            
    }

    return (
        <>
            <div>
                <Col md={12}>
                    {
                        <MaterialTable
                            components={{
                                Pagination: PatchedPagination,
                            }}
                            title={
                                <div>
                                    <Row>
                                        <Col md='auto'>
                                            {
                                                inserir === true ?
                                                    <Button
                                                        disabled={
                                                            (idAutorizacaoFornecimento != undefined &&
                                                                autorizacaoFornecimentoCompleto != null &&
                                                                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                                                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                                                autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null) &&
                                                                listaContratoUsuario != undefined && listaAutorizacaoFornecimentoStatus != undefined &&
                                                                listaStatusAutorizacaoFornecimento != undefined &&
                                                                listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                                                listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true).length > 0
                                                                ?
                                                                // Verifica se o usuário tem a permissão de cancelar a autorização
                                                                (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                    && contratoUsu.idContrato === autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato
                                                                    && contratoUsu.cancelaAutorizacao === true).length === 0)
                                                                    ?
                                                                    true
                                                                    :
                                                                    // Verifica se a autorização ja esta finalizada ou cancelada
                                                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                        listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true)[0].id).length > 0
                                                                        ||
                                                                        listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                                            listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0
                                                                        ?
                                                                        true
                                                                        :
                                                                        false
                                                                :
                                                                null
                                                        }
                                                        variant={"danger"}
                                                        style={{ width: '100%' }}
                                                        onClick={() => CancelarStatusClick("Cancelado")}>
                                                        Cancelar Autorização
                                                    </Button>
                                                    :
                                                    ''
                                            }
                                        </Col>

                                        <Col md='auto'>
                                            {usuarioCompleto != null && usuarioCompleto.grupoAcesso != null && usuarioCompleto.grupoAcesso.nomeGrupo == 'Super Administrador' ?
                                                <Button
                                                    variant={"danger"}
                                                    style={{ width: '100%' }}
                                                    onClick={() => ExcluirUltimoStatus()}>
                                                    Voltar status
                                                </Button>
                                                : ''
                                            }
                                        </Col>

                                        <Col md='auto'>
                                            {usuarioCompleto != null && usuarioCompleto.grupoAcesso != null && usuarioCompleto.grupoAcesso.nomeGrupo == 'Super Administrador' ?
                                                <Button
                                                    variant={"danger"}
                                                    style={{ width: '100%' }}
                                                    onClick={() => HandleShowExcluirAutorizacaoFornecimento()}>
                                                    Excluir AF
                                                </Button>
                                                : ''
                                            }
                                        </Col>

                                        <Col md='auto'>
                                            <Button
                                                disabled={listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                                    listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true).length > 0 &&
                                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                        listaStatusAutorizacaoFornecimento.filter(x => x.finalizaAutorizacao == true)[0].id).length > 0
                                                    ? false : true}
                                                hidden={listaContratoUsuario && autorizacaoFornecimentoCompleto && listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                    && contratoUsu.idContrato === autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato
                                                    && contratoUsu.reabrirAutorizacao === true).length === 0
                                                    ?
                                                    true : false}
                                                variant={"danger"}
                                                style={{ width: '100%' }}
                                                onClick={(e) => (ReabrirAFStatus(), e.currentTarget.disabled=true)}>
                                                Reabrir Autorização
                                            </Button>
                                        </Col>


                                    </Row>
                                </div>
                            }
                            columns={[
                                {
                                    title: 'Status', field: 'status', cellStyle: {
                                        width: '40%'
                                    }
                                },
                                {
                                    title: 'Nome', field: 'usuario', cellStyle: {
                                        width: '20%'
                                    }
                                },
                                {
                                    title: 'Data', field: 'dataInclusao', cellStyle: {
                                        width: '20%'
                                    }
                                },
                                {
                                    title: 'Ação', field: 'aprovacao', cellStyle: {
                                        width: '20%'
                                    }
                                }
                            ]}
                            data={
                                idAutorizacaoFornecimento != undefined &&
                                    listaStatusAutorizacaoFornecimento != undefined && listaAutorizacaoFornecimentoStatus != undefined ?
                                    listaAutorizacaoFornecimentoStatus.map(status => {
                                        return {
                                            observacao: status.observacao,
                                            status:
                                                status.statusAutorizacaoFornecimento != null &&
                                                    status.statusAutorizacaoFornecimento.cancelaAutorizacao == true ?
                                                    <div className="divStatusDanger">{status.statusAutorizacaoFornecimento.descricao}</div>
                                                    : <div className="divStatusGreen">{status.statusAutorizacaoFornecimento.descricao}</div>,
                                            usuario:
                                                status.usuario != null ?
                                                    status.usuario.nome
                                                    : '',
                                            dataInclusao: moment(status.dataStatus).format('L LT'),
                                            aprovacao:
                                                listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined &&
                                                    listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true).length > 0 &&
                                                    listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                        listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true)[0].id).length > 0 ?
                                                    // Se o status atual é cancelado, libera o botão de reativar autorização
                                                    status.statusAutorizacaoFornecimento.cancelaAutorizacao == true ?
                                                        <Button
                                                            disabled={
                                                                idAutorizacaoFornecimento != undefined &&
                                                                    autorizacaoFornecimentoCompleto != null &&
                                                                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                                                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                                                    autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
                                                                    listaContratoUsuario != undefined
                                                                    ?
                                                                    // Libera pra reativar so quem tem permissão de cancelar
                                                                    (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                        && contratoUsu.idContrato ==
                                                                        autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato
                                                                        && contratoUsu.cancelaAutorizacao == true).length === 0)
                                                                        ? true
                                                                        : false
                                                                    :
                                                                    null
                                                            }
                                                            variant="success"
                                                            onClick={() => ResetarStatus(status.id)}
                                                        >Reativar Autorização</Button>
                                                        :
                                                        ''
                                                    :
                                                    ''
                                        }
                                        // Concatenar a lista de status restantes, que ainda não foram confirmados
                                    }).concat(

                                        listaStatusAutorizacaoFornecimento.map(statusSol => {
                                            // Verifica se a autorização esta cancelada, se sim, não mostra os possíveis status futuros
                                            if (listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true).length > 0 &&
                                                listaAutorizacaoFornecimentoStatus.filter(x => x.idStatusAutorizacaoFornecimento ==
                                                    listaStatusAutorizacaoFornecimento.filter(x => x.cancelaAutorizacao == true)[0].id).length > 0
                                            ) {
                                                return null
                                            } else {
                                                // Verifica se o status possui anterior ou ja foi confirmado
                                                if (statusSol.idStatusAutorizacaoAnterior == null || listaAutorizacaoFornecimentoStatus.
                                                    filter(x => x.idStatusAutorizacaoFornecimento == statusSol.id).length > 0) {
                                                    return null
                                                } else {
                                                    return {
                                                        id: '',
                                                        status: <div className="divStatusNormal">{statusSol.descricao}</div>,
                                                        usuario: '',
                                                        dataInclusao: '',
                                                        aprovacao:
                                                            listaAutorizacaoFornecimentoStatus != undefined && listaStatusAutorizacaoFornecimento != undefined && listaAutorizacaoFornecimentoStatus.filter
                                                                (x => x.idStatusAutorizacaoFornecimento == statusSol.idStatusAutorizacaoAnterior).length > 0 &&
                                                                listaStatusAutorizacaoFornecimento.filter(x => x.id == statusSol.idStatusAutorizacaoAnterior).length > 0 ?
                                                                <Button
                                                                    variant="success"
                                                                    disabled={
                                                                        idAutorizacaoFornecimento != undefined &&
                                                                            autorizacaoFornecimentoCompleto != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao != null &&
                                                                            autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato != null &&
                                                                            listaContratoUsuario != undefined ?
                                                                            // Verifica se o usuário tem permissão para finalizar a autorização
                                                                            statusSol.permissaoFinalizar == true && (listaContratoUsuario.filter((contratoUsu) => contratoUsu.idUsuario === usuario.id
                                                                                && contratoUsu.idContrato === autorizacaoFornecimentoCompleto.solicitacaoFornecimentoVersao.solicitacaoFornecimento.contratoVersao.idContrato &&
                                                                                contratoUsu.finalizaAutorizacao === true).length === 0)
                                                                                ? true : false
                                                                            :
                                                                            null
                                                                    }
                                                                    onClick={() => MudarStatusClick(statusSol.descricao)}>
                                                                    Mudar Status
                                                                </Button>
                                                                :
                                                                ''
                                                    }
                                                }
                                            }
                                        }).filter(Boolean)
                                    )
                                    :
                                    []
                            }
                            options={{
                                minBodyHeight: 500,
                                paging: true,
                                pageSize: 10, // Início
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [10, 50, 200, 300],
                                filterRowStyle: {
                                    backgroundColor: "#FAFAFA"
                                },
                                headerStyle: {
                                    backgroundColor: '#454545',
                                    color: '#FFF',
                                    fontWeight: "bold"
                                },
                                filtering: true,
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                })
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'error',
                                    tooltip: <span style={{ fontSize: '1.5em' }}>{'Obervação Cancelado: ' + rowData.observacao}</span>,
                                    hidden: rowData.observacao == '' || rowData.observacao == undefined ? true : false,
                                })
                            ]}
                            localization={{
                                header: {
                                    actions: 'Info'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nenhum registro para exibir',
                                    editRow: {
                                        deleteText: 'Você tem certeza que deseja excluir essa linha?'
                                    },
                                    deleteTooltip: ''
                                },
                                toolbar: {
                                    searchTooltip: 'Pesquisar',
                                    searchPlaceholder: 'Pesquisar'
                                },
                                pagination: {
                                    labelRowsSelect: 'linhas',
                                    labelDisplayedRows: '{count} de {from}-{to}',
                                    firstTooltip: '',
                                    previousTooltip: '',
                                    nextTooltip: '',
                                    lastTooltip: ''
                                },
                                grouping: {
                                    placeholder: 'Arraste a coluna aqui para agrupar',
                                    groupedBy: 'Agrupado por:'
                                }
                            }}
                        />
                    }
                </Col>
            </div >

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja Cancelar essa Autorização?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Observação: </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={descricaoObservacaoCancelado}
                                        onChange={e => setDescricaoObservacaoCancelado(e.target.value)}
                                        required
                                        maxLength="500"
                                        isInvalid={validated && descricaoObservacaoCancelado.trim() === "" ? true : false}
                                        isValid={validated && descricaoObservacaoCancelado.trim() !== "" ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo observação é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir === true ?
                            <Button variant="success" onClick={handleSubmit}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleClose}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showStatus} onHide={handleCloseStatus} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Você deseja Mudar de Status?
                            </Col>
                        </Row>
                        {
                            usuarioCompleto && listaStatusAutorizacaoFornecimento && usuarioCompleto.grupoAcesso &&
                            usuarioCompleto.grupoAcesso.nomeGrupo === 'Super Administrador' &&
                            listaStatusAutorizacaoFornecimento.filter(x => x.descricao === status) &&
                            listaStatusAutorizacaoFornecimento.filter(x => x.descricao === status)[0] && 
                            listaStatusAutorizacaoFornecimento.filter(x => x.descricao === status)[0].descricao === 'Autorização Concluída' ?
                                <Row>
                                    <Col md={12}>
                                        <br/>
                                        <span>Usuário do status:</span>
                                        <Select
                                            placeholder="Selecione..."
                                            isSearchable={true}
                                            isClearable={true}
                                            options={options}
                                            onChange={(e) => { e ? setIdUsuario(e.value) : setIdUsuario(null) }}
                                        />
                                    </Col>
                                </Row>
                                :
                                null
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir === true ?
                            <Button variant="success" onClick={InserirAutorizacaoFornecimentoStatus}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleCloseStatus}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNovoStatus} onHide={handleCloseNovoStatus} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Ao avançar o status irá para o Inicial, deseja prosseguir?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir === true ?
                            <Button variant="success" onClick={ReativarAutorizacao}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleCloseNovoStatus}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmacaoFinalizar} onHide={handleCloseConfirmacaoFinalizar} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                O valor da autorização não foi 100% utilizado, você realmente deseja finaliza-la?
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir === true ?
                            <Button variant="success" onClick={InserirAutorizacaoFornecimentoStatus}>
                                Sim
                            </Button>
                            :
                            ''
                    }

                    <Button variant="danger" onClick={handleCloseConfirmacaoFinalizar}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showExclusao} onHide={handleCloseExclusao} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                Tem certeza que deseja excluir a autorização?
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="formDescricao">
                                <Form.Label>Observação: </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={descricaoObservacaoExclusao}
                                    onChange={e => setDescricaoObservacaoExclusao(e.target.value)}
                                    required
                                    maxLength="500"
                                    isInvalid={descricaoObservacaoExclusao.trim() === "" ? true : false}
                                    isValid={descricaoObservacaoExclusao.trim() !== "" ? true : false}
                                />
                                <Form.Control.Feedback type="invalid">O campo observação é obrigatório.</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => {ExcluirAutorizacaoFornecimento()}}>
                        Sim
                    </Button>

                    <Button variant="danger" onClick={handleCloseExclusao}>
                        não
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}