import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { useEffect } from "react";
import img from '../../assets/login/img_login.png';
import moment from 'moment';
import cabecalho from '../../assets/novoCabecalhoCor.jpg';
import rodape from '../../assets/novoRodapeCor.jpg';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontSize: '10',
  },
  titulo: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '15',
  },
  linha: {
    borderBottom: '1px solid #0000',
    width: '100%',
  },
  espaco: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  table: {
    width: '100%',
  },
  section: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },

  rowCabecalho: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold'
  },
  bordaCompleta: {
    border: '1px solid #0000',
    margin: 1
  },
  comeco: {
    width: "25%",
  },

  meio: {
    width: "50%",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center'
  },

  fim: {
    width: "35%",
  },
  linhaMetadeInicio: {
    width: "50%",
  },
  linhaSemiCompleta: {
    width: "85%",
  },
  fimLinhaSemiCompleta: {
    width: "15%",
  },
  linhaCompleta: {
    width: "100%",
  },
  width8: {
    width: "8%"
  },
  width10: {
    width: "10%"
  },
  width20: {
    width: "20%"
  },
  cabecalho: {
    color: '#fff',
    backgroundColor: '#212529',
    bordercolor: '#32383e'
  },
  cinza: {
    backgroundColor: '#e9ecef',
  },
  logo: {
    width: '200',
    height: '100'
  },
  top20: {
    top: '20'
  },
  top40: {
    top: '40'
  },
  top60: {
    top: '60'
  },
  recuo: {
    textIndent: '40px'
  },
  comecoMaior: {
    width: "40%",
  },
  alinharBottom: {
    position: 'absolute',
    bottom: '0',
    padding: '5',
  },
  rodape: {
    width: '585',
    height: '50',
  },
});

export default function PdfAutorizacaoFornecimento({ data, assinanteSecretario }) {

  useEffect(() => {
    moment.locale('pt-br');
  }, [])

  return (
    <Document>
      {
        data != null ?

          <Page size="A4" style={styles.page}>
            <View style={[styles.section]}>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comecoMaior}></Text>
                <Text style={[styles.meio]}>
                  <Image style={styles.logo} src={cabecalho} />
                </Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}>
                  AF Nº: {data.numeroAf}
                </Text>
                <Text style={[styles.meio]}></Text>
                <Text style={styles.fim}>
                  Fortaleza, {moment(moment((data.data)).add('hours', 2)).format('LL')}
                </Text>
              </View>
              <View style={[styles.rowCabecalho]}>
                <Text style={styles.comeco}>
                  OFÍCIO Nº: {data.numeroOficio}
                </Text>
                <Text style={[styles.meio]}></Text>
                <Text style={styles.fim}>
                </Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaMetadeInicio}>
                  Sr(a): {data.destinatario}
                </Text>
                <Text style={styles.meio}></Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaMetadeInicio}>
                  Gerente de Contrato: {data.nomeFornecedor}
                </Text>
                <Text style={styles.meio}></Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaCompleta}>
                  Rua Padre Severiano - Messejana, Fortaleza - CE, 60871-565
                </Text>
                <Text style={styles.meio}></Text>
                <Text style={styles.fim}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={[styles.linhaCompleta, styles.recuo]}>
                  Nos termos do contrato nº {data.descricaoContrato}, firmado entre o MUNICÍPIO DE FORTALEZA e a empresa {data.nomeFornecedor},
                  para execução de obras e serviços de engenharia concenentes a gestão do Parque de Iluminação Pública do Munícipio de
                  Fortaleza, com relevante no item 5, ambos do Projeto Básico, fica a Contratada autorizada a executar os serviços
                  caracterizados a seguir:
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={[styles.linhaSemiCompleta, styles.recuo]}>
                  a. Título da Obra: {data.tituloObra}
                </Text>
                <Text style={styles.fimLinhaSemiCompleta}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={[styles.linhaSemiCompleta, styles.recuo]}>
                  b. Processo Nº. {data.numeroProcesso}
                </Text>
                <Text style={styles.fimLinhaSemiCompleta}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={[styles.linhaSemiCompleta, styles.recuo]}>
                  c. Local: {data.local}
                </Text>
                <Text style={styles.fimLinhaSemiCompleta}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={[styles.linhaSemiCompleta, styles.recuo]}>
                  d. Valor: {data.valor} conforme planilha de atividades e preços aprovados.
                </Text>
                <Text style={styles.fimLinhaSemiCompleta}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={[styles.linhaSemiCompleta, styles.recuo]}>
                  e. Prazo de Execução: {data.prazo}
                </Text>
                <Text style={styles.fimLinhaSemiCompleta}></Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={[styles.linhaSemiCompleta, styles.recuo]}>
                  f. Tipo de Obra: {data.tipoSolicitacao}
                </Text>
                <Text style={styles.fimLinhaSemiCompleta}></Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  ________________________________________________________
                </Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  {data.autorizador}
                </Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  COORDENADOR DA COEIP
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              {
                assinanteSecretario ?
                <View>
                    <View style={[styles.rowCabecalho, styles.bold]} >
                      <Text style={styles.linhaSemiCompleta}>
                        ________________________________________________________
                      </Text>
                    </View>
                    <View style={[styles.rowCabecalho, styles.bold]} >
                      <Text style={styles.linhaSemiCompleta}>
                        {assinanteSecretario}
                      </Text>
                    </View>
                    <View style={[styles.rowCabecalho, styles.bold]} >
                      <Text style={styles.linhaSemiCompleta}>
                        SECRETÁRIO DA SECRETARIA DE CONSERVAÇÃO E SERVIÇOS PÚBLICOS - SCSP
                      </Text>
                    </View>
                    <View style={[styles.espaco]}></View>
                    <View style={[styles.espaco]}></View>   
                  </View>
                  :
                  <View></View>
              }
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  Cliente,
                </Text>
              </View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.espaco]}></View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  ________________________________________________________
                </Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  {data.destinatario}
                </Text>
              </View>
              <View style={[styles.rowCabecalho, styles.bold]} >
                <Text style={styles.linhaSemiCompleta}>
                  GERENTE DE CONTRATO - {data.nomeFornecedor}
                </Text>
              </View>

            </View>
            <View style={styles.alinharBottom}>
              <Image style={styles.rodape} src={rodape} />
            </View>
          </Page>
          :

          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>
                ERRO AO GERAR PDF
              </Text>
            </View>
          </Page>

      }
    </Document >
  )
}