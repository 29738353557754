export function ListarAutorizacaoFornecimentoRequest() {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_REQUEST'
  }
}

export function ListarAutorizacaoFornecimentoDoEmpenhoRequest(idEmpenho) {
  return {
    type: 'LISTAR_AUTORIZACAO_DO_EMPENHO_FORNECIMENTO_REQUEST',
    idEmpenho
  }
}

export function ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemRequest(idEmpenho, idContrato, idFornecedor) {
  return {
    type: 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_REQUEST',
    idEmpenho, idContrato, idFornecedor
  }
}

export function ListarCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitemResponse(listaCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem) {
  return {
    type: 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_RESPONSE',
    listaCompletoHierarquiaAutorizacaoMedicaoFinalizadaSubitem
  }
}

export function ListarCompletoAutorizacaoFornecimentoComRedirecionamentoRequest(idSolicitacaoFornecimento) {
  return {
    type: 'LISTAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_COM_REDIRECIONAMENTO_REQUEST',
    idSolicitacaoFornecimento
  }
}

export function BuscarCompletoAutorizacaoFornecimentoRequest(idAutorizacaoFornecimento) {
  return {
    type: 'BUSCAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_REQUEST',
    idAutorizacaoFornecimento
  }
}

export function BuscarAutorizacaoFornecimentoPorIdRequest(idAutorizacaoFornecimento) {
  return {
    type: 'BUSCAR_AUTORIZACAO_FORNECIMENTO_POR_ID_REQUEST',
    idAutorizacaoFornecimento
  }
}

export function BuscarAutorizacaoFornecimentoPorIdResponse(autorizacaoFornecimento) {
  return {
    type: 'BUSCAR_AUTORIZACAO_FORNECIMENTO_POR_ID_RESPONSE',
    autorizacaoFornecimento
  }
}

export function BuscarCompletoAutorizacaoFornecimentoResponse(autorizacaoFornecimentoCompleto) {
  return {
    type: 'BUSCAR_COMPLETO_AUTORIZACAO_FORNECIMENTO_RESPONSE',
    autorizacaoFornecimentoCompleto
  }
}

export function ListarAutorizacaoFornecimentoResponse(listaAutorizacaoFornecimento) {
  return {
    type: 'LISTAR_AUTORIZACAO_FORNECIMENTO_RESPONSE',
    listaAutorizacaoFornecimento
  }
}

export function InserirAutorizacaoFornecimentoRequest(data) {
  return {
    type: 'INSERIR_AUTORIZACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function AlterarAutorizacaoFornecimentoRequest(data) {
  return {
    type: 'ALTERAR_AUTORIZACAO_FORNECIMENTO_REQUEST',
    data
  }
}

export function ExcluirAutorizacaoFornecimentoRequest(id, observacao) {
  return {
    type: 'EXCLUIR_AUTORIZACAO_FORNECIMENTO_REQUEST',
    id, observacao
  }
}

export function LimparAutorizacao() {
  return {
    type: 'LIMPAR_AUTORIZACAO'
  }
}

export function Carregando(status) {
  return {
    type: 'CARREGANDO',
    status
  }
}


